import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery= function() {
    /* MAILCHIMP */
    const [box01, setbox01] = React.useState(false);
    const click01 = (): void => {
      setbox01(!box01);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click01close = (): void => {
      setbox01(!box01);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* INNOVATORS */
    const [box02, setbox02] = React.useState(false);
    const click02 = (): void => {
      setbox02(!box02);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click02close = (): void => {
      setbox02(!box02);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* RIR */
    const [box03, setrir] = React.useState(false);
    const click03 = (): void => {
        setrir(!box03);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click03close = (): void => {
        setrir(!box03);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* BETTER */
    const [box04, setbox04] = React.useState(false);
    const click04 = (): void => {
        setbox04(!box04);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click04close = (): void => {
        setbox04(!box04);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* BEST OF TWEETS */
    const [box05, setbox05] = React.useState(false);
    const click05 = (): void => {
        setbox05(!box05);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click05close = (): void => {
        setbox05(!box05);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* PAGO EM LIXO */
    const [box06, setbox06] = React.useState(false);
    const click06 = (): void => {
        setbox06(!box06);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click06close = (): void => {
        setbox06(!box06);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* STEELWAVE */
    const [box07, setbox07] = React.useState(false);
    const click07 = (): void => {
        setbox07(!box07);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click07close = (): void => {
        setbox07(!box07);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* BRADESCO */
    const [box08, setbox08] = React.useState(false);
    const click08 = (): void => {
        setbox08(!box08);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click08close = (): void => {
        setbox08(!box08);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* F DE X */
    const [box09, setbox09] = React.useState(false);
    const click09 = (): void => {
        setbox09(!box09);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click09close = (): void => {
        setbox09(!box09);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* GRAND+NASH */
    const [box10, setbox10] = React.useState(false);
    const click10 = (): void => {
        setbox10(!box10);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click10close = (): void => {
        setbox10(!box10);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* THE LAB */
    const [box11, setbox11] = React.useState(false);
    const click11 = (): void => {
        setbox11(!box11);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click11close = (): void => {
        setbox11(!box11);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* NEWFRONT */
    // const [box12, setbox12] = React.useState(false);
    // const click12 = (): void => {
    //     setbox12(!box12);
    //   var x = document.getElementsByTagName("BODY")[0];
    //   x.style.overflow = "hidden";
    // };
    // const click12close = (): void => {
    //     setbox12(!box12);
    //   var x = document.getElementsByTagName("BODY")[0];
    //   x.style.overflow = "auto";
    // };

    /* ZILLOW */
    const [box13, setbox13] = React.useState(false);
    const click13 = (): void => {
        setbox13(!box13);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click13close = (): void => {
        setbox13(!box13);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* FIND 2 ME */
    const [box14, setbox14] = React.useState(false);
    const click14 = (): void => {
        setbox14(!box14);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const click14close = (): void => {
        setbox14(!box14);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* GENOS */
    // const [box15, setbox15] = React.useState(false);
    // const click15 = (): void => {
    //     setbox15(!box15);
    //   var x = document.getElementsByTagName("BODY")[0];
    //   x.style.overflow = "hidden";
    // };
    // const click15close = (): void => {
    //     setbox15(!box15);
    //   var x = document.getElementsByTagName("BODY")[0];
    //   x.style.overflow = "auto";
    // };

    return(
            <div className="container">
            <GlobalStyles/>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>Motion Reel</h2>
                        <div className="space-border"></div>
                        <iframe className="video-vimeo" src="https://player.vimeo.com/video/836515559?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Motion Reel / lhenrique.com.br"></iframe>
                    </div>
                    <div className="col-md-12 text-center">
                        <h2><br /><br />Portfolio</h2>
                        <div className="space-border"></div>
                    </div>
                </div>
                 <Masonry
                    className={"row my-gallery-class"} elementType={"div"}>

                    {/* THUMBS */} 
                    
                    {/* MAILCHIMP */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click01}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Mailchimp</h3>
                                <h5 className="d-tag">video</h5>
                            </div>
                            <img src="./work/mailchimp/mailchimp.jpg" alt="gallery"/>
                        </div>
                    </div>
                    
                    {/* TWITTER INNOVATORS */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click02}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Twitter Innovators</h3>
                                <h5 className="d-tag">ad video</h5>
                            </div>
                            <img src="./work/twitterinnovators/twitterinnovators.jpg" alt="gallery"/>
                        </div>
                    </div>
                    
                    {/* RIR GAME */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click03}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Rock in Rio Racing</h3>
                                <h5 className="d-tag">promo video</h5>
                            </div>
                            <img src="./work/rirgame/rirgame.jpg" alt="gallery"/>
                        </div>
                    </div>
                    
                    {/* TWITTER BOT */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click05}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Best of Tweets</h3>
                                <h5 className="d-tag">ad video</h5>
                            </div>
                            <img src="./work/twitterbot/twitterbot.jpg" alt="gallery"/>
                        </div>
                    </div>
                    
                    {/* BETTER */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click04}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Better</h3>
                                <h5 className="d-tag">ads</h5>
                            </div>
                            <img src="./work/better/better.jpg" alt="gallery"/>
                        </div>
                    </div>
                    
                    {/* PAGO EM LIXO */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click06}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Paid in Trash</h3>
                                <h5 className="d-tag">promo video</h5>
                            </div>
                            <img src="./work/pagoemlixo/pagoemlixo.jpg" alt="gallery"/>
                        </div>
                    </div>

                    {/* STEELWAVE */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click07}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Steelwave</h3>
                                <h5 className="d-tag">UX motion</h5>
                            </div>
                            <img src="./work/sw/sw.jpg" alt="gallery"/>
                        </div>
                    </div>

                    {/* BRADESCO */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click08}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Bradesco Fake Ad</h3>
                                <h5 className="d-tag">ads</h5>
                            </div>
                            <img src="./work/bradesco/bradesco.jpg" alt="gallery"/>
                        </div>
                    </div>

                    {/* F DE X */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click09}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>F de X</h3>
                                <h5 className="d-tag">promo video</h5>
                            </div>
                            <img src="./work/fdex/fdex.jpg" alt="gallery"/>
                        </div>
                    </div>

                    {/* GRAND + NASH */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click10}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Grand+Nash</h3>
                                <h5 className="d-tag">UX motion</h5>
                            </div>
                            <img src="./work/grandnash/grandnash.jpg" alt="gallery"/>
                        </div>
                    </div>

                    {/* THE LAB */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click11}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>The Lab</h3>
                                <h5 className="d-tag">UX motion</h5>
                            </div>
                            <img src="./work/thelab/thelab.jpg" alt="gallery"/>
                        </div>
                    </div>

                    {/* NEWFRONT <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click12}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Newfront</h3>
                                <h5 className="d-tag">UX motion</h5>
                            </div>
                            <img src="./work/newfront/newfront.jpg" alt="gallery"/>
                        </div>
                    </div> */}

                    {/* ZILLOW */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click13}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Zillow</h3>
                                <h5 className="d-tag">ads</h5>
                            </div>
                            <img src="./work/zillow/zillow.jpg" alt="gallery"/>
                        </div>
                    </div>

                    {/* FIND 2 ME */} <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click14}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Fullpack Find 2 Me</h3>
                                <h5 className="d-tag">promo video</h5>
                            </div>
                            <img src="./work/find2me/find2me.jpg" alt="gallery"/>
                        </div>
                    </div>

                    {/* GENOS <div className="col-lg-4 image-element-class de_modal de_modal" onClick={click15}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Genos</h3>
                                <h5 className="d-tag">UX motion</h5>
                            </div>
                            <img src="./work/genos/genos.jpg" alt="gallery"/>
                        </div>
                    </div> */}
                  </Masonry>

                {/* WORKS PAGES */}

                {/* MAILCHIMP */} {box01 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={click01close}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <video className="pop" controls autoPlay loop>
                                  <source src="./work/mailchimp/01.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-124 de_project-info">
                                <h3>Mailchimp Promotional Video</h3>
                                <p>Life is full of stories. And an amazing way to tell them is through animation. In the project for Mailchimp's promotional video, I had the chance to contribute by putting motion and life into ideas.</p> 
                                <p>The challenge here was to summarize and present a series of Mailchimp lectures, crafted in the same style as TED Talks, in a creative, engaging, and effective way. And that's exactly what animation can do.</p>
                                <p>It's incredible how we can bring the most complex concepts and speeches to life, simplifying them and making them more accessible to the audience. The animation of the main video was a meticulous work that required a lot of attention to detail, but the end result was a visual spectacle that managed to convey the message in a clear and engaging way.</p>
                                <p>In this project, our goal was to keep the audience interested, informed, and excited about Mailchimp's lectures, and I believe we achieved this goal. The result is a promotional video that is not only educational, but also fun and visually impressive.</p>

                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Mailchimp</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-commenting-o"></i>My Contribution: <span>Video Animation</span>
                                    </div>
                                    {/* <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                    </div> */}
                                </div>
                                <div className="spacer-30"></div>
                                {/* <blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                {/* INNOVATORS */} {box02 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={click02close}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <video className="pop" controls autoPlay loop>
                                  <source src="./work/twitterinnovators/01.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-12 de_project-info">
                                <h3>Twitter Innovators</h3>
                                <p>Nothing is more powerful than recognizing and celebrating creativity, right? In the Twitter Innovators project, I had the opportunity to contribute to this cause, bringing to life an animated campaign that highlights the best of advertising agency professionals on Twitter.</p>
                                <p>Animation was the beating heart of this project. We worked to create a main video and various highlights for Twitter's social networks, with the aim of announcing and promoting a super special award.</p>
                                <p>It was an atmosphere of anticipation and excitement, and our challenge was to transmit this in every animated frame. We wanted viewers to feel this adrenaline, this expectation. Each piece of the campaign was carefully animated to bring this feeling to life.</p>
                                <p>The end result was a teaser that not only announced the award but did so in a vibrant, captivating, and inspiring way. Each animated element served to increase the expectation and celebrate the innovation and creativity of these professionals. It was a pleasure to be part of a project that recognizes and applauds innovation in the world of advertising.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Twitter</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-commenting-o"></i>My Contribution: <span>Video Animation</span>
                                    </div>
                                    {/* <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                    </div> */}
                                </div>
                                <div className="spacer-30"></div>
                                {/* <blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                
                {/* RIR GAME */} {box03 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={click03close}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <video className="pop" controls autoPlay loop>
                                  <source src="./work/rirgame/01.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <video className="pop-plus" controls loop>
                                  <source src="./work/rirgame/02.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Rock in Rio Racing</h3>
                                <p>When it comes to music and fun, few things are as iconic as Rock in Rio. And in the Rock in Rio Racing project, I had the amazing opportunity to bring this vibrant universe to the screen through animation.</p>
                                <p>This project was a partnership with ArtPlan, the agency responsible for promoting the event. As a freelancer, I deeply involved myself in creating the motion graphics for the campaign, setting each character in motion, animating the main campaign video and the game assets that were launched as part of the promotion.</p>
                                <p>It was a task full of energy and rhythm, just like the festival itself. Each frame of the video captured the essence of Rock in Rio: the joy, the passion for music, the adrenaline of the race in the game.</p>
                                <p>The end result was a dynamic and captivating campaign that not only announced the festival and the game but also captured the essence of what Rock in Rio represents. It was an amazing experience to be able to bring this project to life and help promote such an iconic event beloved by the public.</p>
                                <p></p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-tachometer"></i>Agency: <span>Art Plan</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Rock in Rio</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-commenting-o"></i>My Contribution: <span>Video Animation</span>
                                    </div>
                                    {/* <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                    </div> */}
                                </div>
                                <div className="spacer-30"></div>
                                {/* <blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                {/* BETTER */} {box04 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={click04close}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <video className="pop" controls autoPlay loop>
                                  <source src="./work/better/01.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <video className="pop-plus" controls loop>
                                  <source src="./work/better/02.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <video className="pop-plus" controls loop>
                                  <source src="./work/better/03.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <video className="pop-plus" controls loop>
                                  <source src="./work/better/04.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <video className="pop-plus" controls loop>
                                  <source src="./work/better/05.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <video className="pop-plus" controls loop>
                                  <source src="./work/better/06.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <video className="pop-plus" controls loop>
                                  <source src="./work/better/07.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Better</h3>
                                <p>In today's world, a dynamic and appealing digital presence is an essential component for any company. For Better, this dynamism was created through the power of motion graphics, one of the most effective ways to capture attention and engage audiences on social networks and websites.</p>
                                <p>During my stint at HAUS, I had a chance to work on various campaigns for Better. Most of these campaigns consisted of animated videos and banners, which created a vibrant and interactive virtual environment.</p>
                                <p>As an animator, my task was to bring these graphic elements to life, turning static designs into animated pieces that told stories and connected Better with its audience.</p>
                                <p>In each project, I sought to deliver animations that captured the essence of Better, its values, and its vision. The created assets were not only attractive visuals but also powerful messages that helped build Better's digital identity.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Better</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-commenting-o"></i>My Contribution: <span>Ads Animation</span>
                                    </div>
                                    {/* <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                    </div> */}
                                </div>
                                <div className="spacer-30"></div>
                                {/* <blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                
                {/* TWITTER BOT */} {box05 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click05close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-8">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/twitterbot/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                              </video>
                              <video className="pop-plus" controls autoPlay loop>
                                <source src="./work/twitterbot/02.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                              </video>
                          </div>

                          <div className="col-lg-4 de_project-info">
                              <h3>Twitter Best of Tweets</h3>
                              <p>In the world of Twitter, each tweet can be a piece of art - a unique expression of thought, humor, information, or emotion. In the "Twitter Best Of Tweets" campaign, the challenge was to bring to life the celebration of these little pieces of expression.</p>
                              <p>As part of the HAUS team responsible for Twitter projects, I had the pleasure of animating all the elements of this campaign. Our goal was to promote the award for the best tweets, and for this, we created a series of animated pieces, including the main campaign video.</p>
                              <p>Each animation was carefully designed to capture the energy and essence of Twitter - a space where words come alive and become conversations, ideas, and movements. We wanted each animated piece to reflect this, combining the excitement of the winning tweets with Twitter's vibrant and dynamic aesthetic.</p>
                              <p>The end result was an animated campaign that not only promoted the award for the best tweets but also celebrated the diversity and creativity found on the Twitter platform. Working on this project was an incredible opportunity to explore the power of animation to tell stories, evoke emotions, and bring to life the essence of such an influential and vibrant brand like Twitter.</p>
                             
                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>Twitter</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Ads Animation</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* PAGO EM LIXO */} {box06 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click06close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-12">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/pagoemlixo/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                              </video>
                          </div>

                          <div className="col-lg-12 de_project-info">
                              <h3>Paid in Trash</h3>
                              <p>If there's something powerful, it's the capacity that animation has to transform important messages into visually captivating experiences. And that's exactly what we did in the "Paid in Trash" project, an initiative by the City Hall of Campolide, in Portugal, aimed at reducing the amount of waste in the city.</p>
                              <p>Half the Pipe, an agency led by Henrique Neves, spearheaded the creation of the campaign. As a freelancer, I had the privilege of being chosen to bring this cause to life, animating the main campaign video in partnership with the talented illustrator Arthur Porto.</p>
                              <p>The responsibility was great. We wanted to awaken environmental awareness and encourage more sustainable behaviors through a visually appealing and effective narrative. Therefore, each animated frame was thoughtfully designed, seeking to portray the urgency of the situation, but also the hope of a cleaner and healthier future.</p>
                              <p>The end result was a video that not only captured attention but also incited action, contributing to the collective effort of making Campolide a cleaner and more sustainable place. It was an enriching experience, reaffirming my conviction that animation has a crucial role to play in important issues like sustainability.</p>
                             
                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>Half the Pipe</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>Campolide City Hall</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Video Animation</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* STEELWAVE */} {box07 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click07close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-12">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/sw/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                              </video>
                          </div>

                          <div className="col-lg-12 de_project-info">
                              <h3>Twitter Best of Tweets</h3>
                              <p>The internet is our gateway to the world. And a well-designed website is like a welcoming host, inviting us in to stay a while. In the Steelwave prototype project, I had the opportunity to play the role of this virtual host.</p> 
                              <p>HAUS, responsible for creating and developing Steelwave's website, entrusted me with the task of developing an animated prototype of this site. As an animator, the challenge here was to create a user experience that was both functional and appealing.</p> 
                              <p>Combining animation techniques with the principles of UX Motion, I worked to create a design that flowed intuitively and was pleasing to the eye. Each animation was carefully thought out to ensure smooth and uncomplicated navigation, transforming the user's journey into a pleasant and easy experience.</p> 
                              <p>The final result was a prototype that, in addition to being visually appealing, provided an effective and efficient user experience. And in the end, this prototype served as a guide for the development team, who successfully implemented it. It was an exciting work, combining creativity and technique to create an interface that Steelwave could use to welcome its online visitors.</p> 

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>SteelWave</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Proto Animation</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* BRADESCO */} {box08 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click08close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-12">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/bradesco/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                              </video>
                          </div>

                          <div className="col-lg-12 de_project-info">
                              <h3>Bradesco Fake Ad</h3>
                              <p>The best projects are those that challenge us to think outside the box, and "Bradesco Fake Ad" was definitely one of them. This campaign, created by Almap BBDO for Bradesco Insurance, aimed to make people think about the importance of having car insurance, addressing the inevitability of unforeseen events in a quite creative way.</p> 
                              <p>I was brought in as a freelancer to animate the mobile piece of the campaign. Working alongside Art Director André Leotta was an amazing experience, as I had the opportunity to be involved in all stages of the development of this piece, from conception to final animation.</p> 
                              <p>The campaign was a resounding success, resulting in Brazil's first Gold in the Mobile category at the prestigious Cannes Advertising Festival. This was undoubtedly one of the most important milestones in my career.</p> 
                              <p>Creating a piece that not only captured people's attention but also made them stop and reflect, was a huge challenge but also incredibly rewarding. This project solidified my passion for animation and reinforced the power it has to communicate important messages in innovative and impactful ways.</p> 

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>Almap BBDO</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>Bradesco Insurance</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Ad Animation</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* F DE X */} {box09 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click09close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-12">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/fdex/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                              </video>
                          </div>

                          <div className="col-lg-12 de_project-info">
                              <h3>F(X)</h3>
                              <p>Working on projects that involve promoting digital products always brings a unique set of challenges and opportunities. And that's exactly what I found when I was hired as a freelancer by eGenius to animate the promotional video for F(x), one of their digital products.</p> 
                              <p>The task here was to transform technical concepts and functionalities of a digital product into an animated, engaging story that would attract viewers' attention and clearly explain the value and benefits of F(x).</p> 
                              <p>Every movement, every transition, every scene was meticulously designed to ensure that the message was conveyed effectively, without losing the audience's interest. We wanted the audience to see F(x) not just as a product, but as a solution that could make their lives easier.</p> 
                              <p>The end result was a promotional video that not only highlighted the strengths of F(x), but also conveyed its essence in a clear and engaging way. It was an amazing experience working on this project and seeing how animation can be a powerful tool to effectively and attractively present digital products.</p> 

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>eGenius</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>eGenius</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Video Animation</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* GRAND+NASH */} {box10 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click10close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-8">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/grandnash/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <video className="pop-plus" controls autoPlay loop>
                                <source src="./work/grandnash/02.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                          </div>

                          <div className="col-lg-4 de_project-info">
                              <h3>GRAND+NASH UX Motion</h3>
                              <p>In the realm of web design, animation plays a crucial role in ensuring that users not only view the site, but also interact with it. This was what we sought to achieve when working on Grand+Nash's website, a project led by HAUS in which I had the privilege to participate.</p> 
                              <p>HAUS was responsible for creating and developing the website for Grand+Nash, and I was brought onboard to breathe life into their layout. The mission was clear: create an animated prototype that was intuitive, engaging, and user-friendly.</p> 
                              <p>To meet this goal, I focused on creating usability animations (UX Motion), transitions, and animations for icons and buttons. Each element was designed to offer a seamless experience, prompting the user to explore and interact with the site.</p> 
                              <p>I strongly believe that every detail matters, and it was this philosophy that I applied when animating the prototype. The end result was a visually captivating website that not only pleased the eye, but also provided a practical and enjoyable user experience. It was a pleasure to be part of this project, and to see how animation and design can come together to create an engaging and effective digital experience.</p> 

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>Grand+Nash</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>HAUS Site Case Cards</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* THE LAB */} {box11 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click11close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-8">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/thelab/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <video className="pop-plus" controls autoPlay loop>
                                <source src="./work/thelab/02.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <video className="pop-plus" controls loop>
                                <source src="./work/thelab/03.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <video className="pop-plus" controls loop>
                                <source src="./work/thelab/04.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <video className="pop-plus" controls loop>
                                <source src="./work/thelab/05.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                          </div>

                          <div className="col-lg-4 de_project-info">
                              <h3>The Lab UX Motion</h3>
                              <p>When design meets animation, magic happens. It was this magic we sought to bring to life while working on The Lab's website, a project led by HAUS in which I had the pleasure to participate as an animator.</p> 
                              <p>My task in this project was to breathe life into the layout created by the HAUS design team. The idea was to create an animated prototype of the website that would provide a rich and engaging user experience. This involved creating usability animations (UX Motion), transitions, and animations for icons and buttons on the website.</p> 
                              <p>The key to this project was attention to detail. Each animation needed to be thought out not just in terms of aesthetics, but also in terms of functionality. We wanted to ensure that the animations facilitated the website's navigation, making the user experience more intuitive and enjoyable.</p> 
                              <p>The end result was a website that, besides being visually stunning, provided a smooth and pleasant user experience. This project was a true collaboration between design and animation, showcasing the incredible potential these two fields have when they work together to create engaging and effective digital experiences.</p> 

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>The Lab</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Proto Animation</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* NEWFRONT {box12 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click12close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-12">
                            <video className="pop" controls autoPlay loop>
                                <source src="./work/newfront/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                          </div>

                          <div className="col-lg-12 de_project-info">
                              <h3>Newfront UX Motion</h3>
                              <p>When HAUS was tasked with creating and developing the website for Newfront, I was invited to join the team as the animator responsible for breathing life into the layout. One of the requirements was the creation of a simple and fluid parallax navigation, a challenge that I accepted with enthusiasm.</p> 
                              <p>The goal was to provide an outstanding user experience through usability animations (UX Motion), transitions, and animations for icons and buttons. Every animated element on the site had to work in harmony with the others, creating a natural flow that kept users engaged and interested.</p> 
                              <p>I created an animated prototype that fully embraced the site's aesthetic, adding a dynamic element that took the user experience to a new level. The parallax navigation was designed to be simple yet effective, guiding users through the site in an intuitive and visually pleasing manner.</p> 
                              <p>In the end, we were able to create a website that was not only functional but also visually engaging. It was an amazing experience to be part of this project, demonstrating once again the power of animation when it comes to enhancing user experience.</p> 

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>Newfront</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Proto Animation</span>
                                  </div>
                              </div>
                              <div className="spacer-30"></div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )} */}

                {/* ZILLOW */} {box13 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click13close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-8">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/zillow/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <video className="pop-plus" controls autoPlay loop>
                                <source src="./work/zillow/02.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                          </div>

                          <div className="col-lg-4 de_project-info">
                              <h3>Zillow Case</h3>
                              <p>The role of animation is not only limited to the user experience on a website or product, but it can also be a crucial tool to showcase and promote these works. This was well exemplified in the Zillow project, where I had the opportunity to create animated GIFs to showcase this case on the HAUS website.</p> 
                              <p>HAUS was responsible for creating and producing Zillow's website, and my role in this project was to take it a step further by creating animations that effectively showcased the work done. The idea was to create animated GIFs that captured the essence of the project, highlighting its key elements and features in a dynamic and engaging way.</p> 
                              <p>Animating these GIFs required careful consideration of what needed to be highlighted and how these highlights could be effectively animated. The result was a series of GIFs that, when showcased on the HAUS website, gave visitors a clear and appealing glimpse of the work that was done on Zillow's website.</p> 
                              <p>This project showcased how animation can be a versatile tool, not only in creating appealing user experiences, but also in showcasing and promoting work. It was a rewarding experience to be a part of this project and help bring Zillow's case to life.</p>  

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>Zillow</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Proto Animation</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* FIND 2 ME */} {box14 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click14close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-12">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/find2me/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                          </div>

                          <div className="col-lg-12 de_project-info">
                              <h3>Fullpack Find 2 Me</h3>
                              <p>In today's increasingly connected world, staying on top of calls on your cell phone is more important than ever. Fullpack came up with an innovative idea: identifying calls on your cell phone based on the contacts of your contacts. My task was to bring this idea to life through an introduction video.</p>
                              <p>As an animator, I created a video that presented the functionality of this new tool in a clear and engaging manner. The idea was to show users how this functionality could benefit them in their day-to-day life, making the management of phone calls much more efficient.</p>
                              <p>The challenge was to explain a relatively complex concept in a simple and accessible way. With a combination of clear graphics and fluid animations, I was able to create a video that not only explained the functionality of the Find 2 Me tool but also highlighted it in an attractive manner.</p>
                              <p>The result was an introduction video that effectively communicated the value of this new tool, while also engaging the audience with visually stimulating animation. This project was a great example of how animation can be used to explain and promote innovative ideas.</p>

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>Fullpack</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>Fullpack</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>Video Animation</span>
                                  </div>
                                  {/* <div className="d-field">
                                      <i className="fa fa-external-link"></i>Preview: <span><a href="https://madeinhaus.com/work/mailchimp" target="_blank" rel="noreferrer">HAUS/work/mailchimp</a></span>
                                  </div> */}
                              </div>
                              <div className="spacer-30"></div>
                              {/* <blockquote>
                                  "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                  <span>John Archi</span>
                              </blockquote> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}

                {/* GENOS {box15 && ( 
                  <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={click15close}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row g-5">
                          <div className="col-lg-12">
                          <video className="pop" controls autoPlay loop>
                                <source src="./work/genos/01.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                          </div>

                          <div className="col-lg-12 de_project-info">
                              <h3>Genos UX Motion</h3>
                              <p>In an increasingly digital world, a website has to be more than just an online presence; it has to be an engaging experience. It was with this philosophy in mind that I worked on the Genos website project, developed by HAUS.</p>
                              <p>As the animator for this project, I was tasked with bringing to life the layout created by the HAUS design team. This included animating various elements and icons, including the main Genos logo.</p>
                              <p>With a focus on usability, I worked on creating animations (UX Motion) that would help guide users through the site intuitively. This involved animating icons and buttons, as well as working on transitions that would make navigation through the site smooth and enjoyable. The idea was for each animation to be not only visually appealing but also useful in enhancing the user experience.</p>
                              <p>Beyond this, I had the special task of animating the introduction to the site, a crucial part in setting the tone of the user experience. The end result was a website that not only looked good but also felt good to use. This project really highlighted the power of animation to enhance user experience, and I am proud to have been a part of it.</p>

                              <div className="de_project-details">
                                  <div className="d-field">
                                      <i className="fa fa-tachometer"></i>Agency: <span>HAUS</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-user-o"></i>Client: <span>Genos</span>
                                  </div>
                                  <div className="d-field">
                                      <i className="fa fa-commenting-o"></i>My Contribution: <span>UX Motion</span>
                                  </div>

                              </div>
                              <div className="spacer-30"></div>

                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )} */}



      </div>
    );
}

export default Gallery;