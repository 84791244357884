import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>My Resume</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-6"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Experiences</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2015 - 2023</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">HAUS Los Angeles</span>
                                	<span className="d_company">Animator</span>
                                	Animator specializing in animation for promotional videos and assets for the sites launched by the studio.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2012 - 2015</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Fullpack Comunicação</span>
                                	<span className="d_company">Motion Designer</span>
                                	At Fullpack, I was responsible for all internal video production, animation of online advertising pieces, and UX Motion for the websites and apps that were produced by the agency.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2010 - 2012</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Wunderman Brasil</span>
                                	<span className="d_company">Motion Designer</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6" 
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Education</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2010 - 2010</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Melies - School of Cinema, 3D and Animation</span>
                                	<span className="d_company">Animação 3D</span> One-year 3D animation course.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2005 - 2009</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">ESPM - Superior School of Advertising and Marketing</span>
                                	<span className="d_company">Higher Education</span> Bachelor's degree in Advertising and Marketing with a focus on Creativity.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2000 - 2004</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Macedo Soares - MV1</span>
                                	<span className="d_company">Ensino Médio</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;